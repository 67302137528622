var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { CpaProductMetadata } from '@millerknoll/pub-api-schema';
import { CpaProductType, saveProduct } from 'utils/cpa-utils';
import { useCpaProduct } from '../CpaProduct/CpaProductContextProvider';
import { CpaUnsavedEditCheckLabel } from './CpaUnsavedEditCheckLabel/CpaUnsavedEditCheckLabel';
import { CpaProductHeaderPricing } from './CpaProductHeaderPricing/CpaProductHeaderPricing';
import { CpaProductHeaderAdditionalInfoWorkOrder } from './CpaProductHeaderProductInfo/CpaProductHeaderProductInfoWorkOrder';
import { CpaProductHeaderAdditionalInfoSpecials } from './CpaProductHeaderProductInfo/CpaProductHeaderProductInfoSpecials';
import { CpaProductMode } from '../CpaProduct/CpaProductReducer';
import styles from './CpaProductHeader.module.scss';
export function CpaProductHeader() {
    var _this = this;
    var _a, _b, _c;
    var cpaProductContext = useCpaProduct();
    var _d = useState(false), saveLoading = _d[0], setSaveLoading = _d[1];
    /**
     * the below usePrompt and useEffect are used to prevent navigation and
     * page close/refresh while a save is in progress
     */
    usePrompt({ when: saveLoading, message: 'Save in progress. Do you still want to navigate?' });
    useEffect(function () {
        if (saveLoading) {
            window.onbeforeunload = function () { return true; };
        }
        else {
            window.onbeforeunload = function () { return undefined; };
        }
    }, [saveLoading]);
    var handleSave = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSaveLoading(true);
                    sessionStorage.setItem("".concat(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.cpn, "_inAddEditMode"), CpaProductMode.EDIT);
                    if (!cpaProductContext) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveProduct(cpaProductContext.data)];
                case 1:
                    _a.sent();
                    cpaProductContext.dispatch({
                        type: 'UPDATE_CURRENT_PRODUCT_ORIGINAL',
                        payload: JSON.parse(JSON.stringify(cpaProductContext.data.currentProduct)),
                    });
                    cpaProductContext.dispatch({
                        type: 'UPDATE_PRICING_TABLE_DATA_ORIGINAL',
                        payload: JSON.parse(JSON.stringify(cpaProductContext.data.pricingTableData)),
                    });
                    _a.label = 2;
                case 2:
                    setSaveLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [cpaProductContext]);
    var anyRestrictions = false;
    if (((_a = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _a === void 0 ? void 0 : _a.restrictions) &&
        Object.values((_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _b === void 0 ? void 0 : _b.restrictions).find(function (el) { return el.isActive === true; })) {
        anyRestrictions = true;
    }
    var saveButtonErrors = useMemo(function () {
        var _a, _b, _c, _d;
        var errorMessages = [];
        var SAVE_BUTTON_ERROR_MESSAGES;
        (function (SAVE_BUTTON_ERROR_MESSAGES) {
            SAVE_BUTTON_ERROR_MESSAGES["LEGACY_EDITS"] = "Edits currently disabled for specials and work orders originating from legacy system.";
            SAVE_BUTTON_ERROR_MESSAGES["DEVIATION_DESC"] = "Deviation description required. (Description tab)";
            SAVE_BUTTON_ERROR_MESSAGES["REQUESTOR"] = "Requestor field required.";
        })(SAVE_BUTTON_ERROR_MESSAGES || (SAVE_BUTTON_ERROR_MESSAGES = {}));
        if (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) {
            if (((_a = cpaProductContext.data.currentProductMetadata) === null || _a === void 0 ? void 0 : _a.sourceSystem) !==
                CpaProductMetadata.sourceSystem.CUSTOM &&
                process.env.REACT_APP_ENABLE_CPA_EDITS_FOR_CSP_PRODUCTS === 'FALSE') {
                errorMessages.push(SAVE_BUTTON_ERROR_MESSAGES.LEGACY_EDITS);
            }
            else {
                if (!((_c = (_b = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data) === null || _b === void 0 ? void 0 : _b.currentProduct) === null || _c === void 0 ? void 0 : _c.deviationDesc)) {
                    errorMessages.push(SAVE_BUTTON_ERROR_MESSAGES.DEVIATION_DESC);
                }
                if (((_d = cpaProductContext.data.currentProduct) === null || _d === void 0 ? void 0 : _d.requestor) === undefined) {
                    errorMessages.push(SAVE_BUTTON_ERROR_MESSAGES.REQUESTOR);
                }
            }
        }
        return errorMessages;
    }, [cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data]);
    return (_jsxs(Box, __assign({ className: styles.container }, { children: [_jsxs(Box, __assign({ className: styles.titleContainer }, { children: [_jsx(Typography, __assign({ className: styles.pageTitle }, { children: "Special Products Customization" })), _jsx(Tooltip, __assign({ title: saveButtonErrors.length === 0 ? ('') : (_jsx("div", __assign({ style: { whiteSpace: 'pre-line' } }, { children: saveButtonErrors.map(function (e) { return e; }).join('\n\n') }))), slotProps: {
                            tooltip: {
                                sx: {
                                    fontSize: '14px',
                                },
                            },
                        }, arrow: true }, { children: _jsxs(Box, __assign({ className: styles.saveContainer }, { children: [_jsx(StyledButtonV2, { labelText: "Save Product", height: "48px", width: "228px", buttonAction: handleSave, disabled: saveButtonErrors.length > 0 }), _jsx(CpaUnsavedEditCheckLabel, {})] })) }))] })), _jsxs(Box, __assign({ className: styles.headerContainer }, { children: [(cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.productType) === CpaProductType.SPECIAL_ORDER && (_jsx(CpaProductHeaderAdditionalInfoSpecials, {})), (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.productType) === CpaProductType.WORK_ORDER && (_jsx(CpaProductHeaderAdditionalInfoWorkOrder, {})), _jsxs(Box, __assign({ className: styles.headerRightHalfContainer }, { children: [((_c = cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.currentProduct) === null || _c === void 0 ? void 0 : _c.standardProductReference) &&
                                cpaProductContext.data.cpn &&
                                (cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.data.productType) === CpaProductType.SPECIAL_ORDER && (_jsx(CpaProductHeaderPricing, { saveInProgress: saveLoading })), anyRestrictions && _jsx("div", __assign({ className: styles.warningMessage }, { children: "Restriction Hold(s)" }))] }))] })), saveLoading && _jsx(LoadingSpinner, { indicatorSize: 10 })] })));
}
